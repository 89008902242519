import { HttpClient } from '@exerai/react-core';
import { Assessment } from '@exerai/vision-engine-web';
import * as Sentry from '@sentry/react';
import { Auth } from 'aws-amplify';
import axios, { AxiosResponse } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import {
    GaitSession,
    PatientRecord,
    CreateUpdatePatientDto,
    UpdateProfile,
    Location,
    Practitioner,
    UserProfile,
    HealthPatientRecord,
    OrganizationHEP,
    Exercise,
    CreateHEPDto,
    HEPTemplate,
    RawSession,
    HEPAssignment,
    Paginated,
    PlainObject,
    Organization,
    CreateOrganizationDto,
    RTMPagedRequestParams,
    PatientPagedRequestParams,
    UpdateRTMCycle,
    RTMCycle,
    Program,
    DashboardData,
    UpdateOrganizationDto,
    CreateLocationDto,
    CreatePractitionerDto,
    ExerciseMetric,
    UserProfileWithInviteCode,
    GaitSessionReport,
    ReconcileResponse,
    PatientInteraction,
    PatientInteractionList,
    Interaction,
    RTMItem,
    RTMPdfUrlDto,
    UpdatePractitionerDto,
    OrganizationLogoResponse,
    AdminLocation,
    ScanSessionAdmin,
    PROTypeformForm,
    CreatePROTypeformFormDto,
    AdminGaitSession,
    PROTypeformResponse,
    OrganizationAdmin,
    UpdateOrganizationAdminDto,
    ClientVersion,
    ScanSessionsPagedRequestParams,
} from '@/common/types';
import { sleep } from '@/common/utils';
import { PROAnswersByQuestionsDict } from '@/components/Health/PatientSessions/PROs/types';
import { ActivityData } from '@/components/Health/PatientSessions/SessionsPDF/types';
import { AssessmentOutcome } from '@/components/Scan/Outcomes/types/types';
import { IHttpService } from './IHttpService';
import { transformHEPTemplateResponse } from './httpServiceUtils';

type SuccessResult<T> = T;
type ErrorResult = { message: string; status: number | null };

export type Result<T> = SuccessResult<T> | ErrorResult;
export type PaginatedResult<T> = Result<Paginated<T>>;

export const resultIsError = (result: SuccessResult<any> | ErrorResult): result is ErrorResult => {
    return (result as ErrorResult).message !== undefined;
};

export class HttpService implements IHttpService {
    private httpClient: HttpClient;

    constructor(httpClient: HttpClient) {
        this.httpClient = httpClient;
    }

    getPatientRecords = async (params: PatientPagedRequestParams): Promise<PaginatedResult<PatientRecord[]>> => {
        const queryString = new URLSearchParams(params as PlainObject).toString();
        try {
            const result = await this.httpClient.get(`v2/patients/pages?${queryString}`);
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    getPatientRecord = async (patientRecordId: number): Promise<Result<PatientRecord>> => {
        try {
            const result = await this.httpClient.get(`v2/patients/${patientRecordId}/`);
            if (result?.data.hidden) {
                throw new Error('Patient is hidden.');
            }
            const hepResult = await this.httpClient.get(`v2/health/patients/${patientRecordId}/heps`);
            if (!hepResult.data) {
                return result.data;
            } else {
                return {
                    ...result.data,
                    productData: {
                        ...result.data.productData,
                        health: { ...result.data.productData?.health, heps: hepResult.data },
                    },
                };
            }
        } catch (e) {
            return await this.handleError(e);
        }
    };

    updatePatientRecord = async (
        patientRecordId: number,
        patient: Partial<CreateUpdatePatientDto>,
    ): Promise<Result<PatientRecord>> => {
        try {
            const result = await this.httpClient.patch(`v2/patients/${patientRecordId}/`, { ...patient });
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    updatePatientRecords = async (
        patientRecordIds: number[],
        patient: Partial<CreateUpdatePatientDto>,
    ): Promise<Result<PatientRecord[]>> => {
        try {
            const result = await this.httpClient.patch(`v2/patients?patientRecordIds=${patientRecordIds.join(',')}`, {
                ...patient,
            });
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    deletePatientRecord = async (patientRecordId: number): Promise<Result<boolean>> => {
        try {
            const result = await this.httpClient.delete(`v2/patients/${patientRecordId}`);
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    getGaitPatientRecords = async (params: PatientPagedRequestParams): Promise<PaginatedResult<PatientRecord[]>> => {
        const queryString = new URLSearchParams(params as PlainObject).toString();
        try {
            const result = await this.httpClient.get(`v2/gait/patients/pages?${queryString}`);
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    getPatientGaitSessions = async (patientRecordId: string): Promise<Result<GaitSession[]>> => {
        try {
            const result = await this.httpClient.get(`v2/gait/sessions?patientRecordId=${patientRecordId}`);
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    getGaitReport = async (startDateTime: string, endDateTime: string): Promise<Result<GaitSessionReport[]>> => {
        try {
            const result = await this.httpClient.get(
                `v2/gait/sessions/report?start=${startDateTime}&end=${endDateTime}`,
            );
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    updateGaitSessionComment = async (sessionId: string, comment: string | null): Promise<Result<GaitSession>> => {
        try {
            const result = await this.httpClient.post(`v2/gait/sessions/${sessionId}/comment`, { comment });
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    getScanSessions = async (params: ScanSessionsPagedRequestParams): Promise<PaginatedResult<ScanSessionAdmin[]>> => {
        const queryString = new URLSearchParams(params as PlainObject).toString();
        try {
            const result = await this.httpClient.get(`v2/scan/sessions/pages?${queryString}`);
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    getScanRiskFactorOutcomes = async (): Promise<Result<AssessmentOutcome[]>> => {
        try {
            const result = await this.httpClient.get(`/v2/scan/outcomes/risk-factors`);
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    addNewPatient = async (newPatient: CreateUpdatePatientDto): Promise<Result<PatientRecord>> => {
        try {
            const result = await this.httpClient.post(`v2/patients`, newPatient);
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    getHealthPatientRecords = async (
        params: PatientPagedRequestParams,
    ): Promise<PaginatedResult<HealthPatientRecord[]>> => {
        const queryString = new URLSearchParams(params as PlainObject).toString();
        try {
            const result = await this.httpClient.get(`v2/health/patients/pages?${queryString}`);
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    getHealthPatientSessions = async (
        patientRecordId: number,
        includeSelfReported?: boolean,
    ): Promise<Result<RawSession[]>> => {
        try {
            const result = await this.httpClient.get(
                `v2/health/patients/${patientRecordId}/sessions${
                    !includeSelfReported ? `?includeSelfReported=false` : ''
                }`,
            );
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    getHealthHEP = async (hepId: number): Promise<Result<OrganizationHEP>> => {
        try {
            const result = await this.httpClient.get(`v2/health/heps/${hepId}`);
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    createHealthHEPAssignments = async (
        hepId: number,
        patientRecordIds: number[],
    ): Promise<Result<HEPAssignment[]>> => {
        try {
            const result = await this.httpClient.post(`v2/health/heps/${hepId}/assignments`, { patientRecordIds });
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    createHealthHEP = async (hepDetails: CreateHEPDto): Promise<Result<Program>> => {
        try {
            const result = await this.httpClient.post(`v2/health/heps`, { ...hepDetails });
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    replaceHealthHEP = async (hepId: number, hepDetails: CreateHEPDto): Promise<Result<Program>> => {
        try {
            const result = await this.httpClient.post(`v2/health/heps/${hepId}/replace`, { ...hepDetails });
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    createHealthHEPAssignment = async (
        hepId: number,
        patientRecordId: number,
        shouldRetireHeps = false,
    ): Promise<Result<HealthPatientRecord>> => {
        try {
            const result = await this.httpClient.post(`v2/health/patients/${patientRecordId}/assignments`, {
                hepId,
                shouldRetireHeps,
            });
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    deleteHealthHEPAssignment = async (hepId: number, patientRecordId: number): Promise<Result<boolean>> => {
        try {
            const result = await this.httpClient.delete(`v2/health/patients/${patientRecordId}/heps/${hepId}`);
            return !!result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    createHEPTemplate = async (templateDetails: HEPTemplate): Promise<Result<HEPTemplate>> => {
        try {
            const result = await this.httpClient.post(`v2/health/hep-templates`, { ...templateDetails });
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    getHEPTemplates = async (): Promise<Result<HEPTemplate[]>> => {
        try {
            const result = await this.httpClient.get(`v2/health/hep-templates`);
            let templates = result.data;

            templates = templates.map((t) => {
                const template = t;
                template.blocks.map((block) => {
                    // patch to compensate for program templates not having a block entity, but a block prototype
                    block.id = uuidv4();
                    return block;
                });
                return template;
            });

            return templates;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    getHEPTemplate = async (templateId: number): Promise<Result<HEPTemplate>> => {
        try {
            const result = await this.httpClient.get(`/v2/health/hep-templates/${templateId}`);
            return transformHEPTemplateResponse(result as AxiosResponse<HEPTemplate, any>);
        } catch (e) {
            return await this.handleError(e);
        }
    };

    deleteHEPTemplate = async (templateId: number): Promise<Result<boolean>> => {
        try {
            await this.httpClient.delete(`/v2/health/hep-templates/${templateId}`);
            return true;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    deleteMultipleHEPTemplates = async (templateIds: number[]): Promise<Result<boolean>> => {
        try {
            await this.httpClient.delete(`/v2/health/hep-templates?ids=${templateIds.toString()}`);
            return true;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    updateHEPTemplate = async (templateId: number, templateDetails: HEPTemplate): Promise<Result<HEPTemplate>> => {
        try {
            const result = await this.httpClient.put(`/v2/health/hep-templates/${templateId}`, { ...templateDetails });
            return transformHEPTemplateResponse(result as AxiosResponse<HEPTemplate, any>);
        } catch (e) {
            return await this.handleError(e);
        }
    };

    healthResendInvite = async (patientRecordId: number): Promise<Result<boolean>> => {
        try {
            await this.httpClient.post(`v2/health/patients/${patientRecordId}/invite`, {});
            return true;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    getLocations = async (): Promise<Result<Location[]>> => {
        try {
            const result = await this.httpClient.get(`v2/locations`);
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    getAdminLocations = async (): Promise<Result<AdminLocation[]>> => {
        try {
            const result = await this.httpClient.get(`v2/admin/locations`);
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    updateAdminLocationKeyword = async (locationId: number, keyword: string | null): Promise<Result<AdminLocation>> => {
        try {
            const payload = { keyword: keyword };
            const result = await this.httpClient.patch(`v2/admin/locations/${locationId}`, payload);
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    getCurrentUser = async (): Promise<Result<UserProfile>> => {
        try {
            const result = await this.httpClient.get(`v2/users/current`);
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    createLocation = async (location: CreateLocationDto): Promise<Result<Location>> => {
        try {
            const result = await this.httpClient.post(`v2/locations`, location);
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    updateLocation = async (locationId: number, location: Partial<Location>): Promise<Result<Location>> => {
        try {
            const result = await this.httpClient.patch(`v2/locations/${locationId}`, location);
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    getPractitioners = async (): Promise<Result<Practitioner[]>> => {
        try {
            const result = await this.httpClient.get(`v2/practitioners`);
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    getCurrentPractitioner = async (): Promise<Result<Practitioner>> => {
        try {
            const result = await this.httpClient.get(`v2/practitioners/current`);
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    createPractitioner = async (practitioner: CreatePractitionerDto): Promise<Result<Practitioner>> => {
        try {
            const result = await this.httpClient.post(`v2/practitioners`, practitioner);
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    updatePractitioner = async (
        practitionerId: number,
        practitioner: Partial<UpdatePractitionerDto>,
    ): Promise<Result<Practitioner>> => {
        try {
            const result = await this.httpClient.patch(`v2/practitioners/${practitionerId}`, practitioner);
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    deletePractitioner = async (practitionerId: number): Promise<Result<boolean>> => {
        try {
            const result = await this.httpClient.delete(`v2/practitioners/${practitionerId}`);
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    practitionerResendInvite = async (practitionerId: number): Promise<Result<boolean>> => {
        try {
            await this.httpClient.post(`v2/practitioners/${practitionerId}/invite`, {});
            return true;
        } catch (e) {
            await this.handleError(e);
            return false;
        }
    };

    getUserProfileByCode = async (code: string) => {
        try {
            const result = await this.httpClient.get(`v2/sign-up/${code}`);
            return result.data;
        } catch (e) {
            await this.handleError(e);
            return undefined;
        }
    };

    updateUserProfile = async (id: number, updateUserProfile: UpdateProfile): Promise<Result<UserProfile>> => {
        try {
            const result = await this.httpClient.patch(`v2/users/${id}`, updateUserProfile);
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    completeSignUp = async (id: number, updateUserProfile: UpdateProfile) => {
        try {
            const result = await this.httpClient.post(`v2/users/${id}/complete-sign-up`, updateUserProfile);
            return result.data;
        } catch (e) {
            await this.handleError(e);
            return undefined;
        }
    };

    getGaitTemplateCsv = async () => {
        try {
            const result = await this.httpClient.get(`exer_bulk_add_template_gait.csv`);
            return result.data;
        } catch (e) {
            await this.handleError(e);
            return undefined;
        }
    };

    getHealthTemplateCsv = async () => {
        try {
            const result = await this.httpClient.get(`exer_bulk_add_template_health.csv`);
            return result.data;
        } catch (e) {
            await this.handleError(e);
            return undefined;
        }
    };

    getGaitReconcileTemplateCsv = async () => {
        try {
            const result = await this.httpClient.get(`exer_gait_reconcile_template.csv`);
            return result.data;
        } catch (e) {
            await this.handleError(e);
            return undefined;
        }
    };

    uploadPatientCsv = async (file, product: string): Promise<Result<number>> => {
        try {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('product', product);
            const result = await this.httpClient.post(`v2/patients/upload`, formData);
            return result.data.importedCount;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    uploadPatientReconciliationCsv = async (file): Promise<Result<ReconcileResponse>> => {
        try {
            const formData = new FormData();
            formData.append('file', file);
            const result = await this.httpClient.post(`v2/patients/reconcile/`, formData);
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    getRTMCycles = async (params: RTMPagedRequestParams): Promise<PaginatedResult<RTMCycle[]>> => {
        const queryString = new URLSearchParams(params as PlainObject).toString();

        try {
            const result = await this.httpClient.get(`/v2/health/rtm-cycles?${queryString}`);
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    getRTMCyclesByMonth = async (params: RTMPagedRequestParams, date: string): Promise<PaginatedResult<RTMItem[]>> => {
        const queryString = new URLSearchParams(params as PlainObject).toString();

        try {
            const result = await this.httpClient.get(`/v2/health/rtm-cycles/${date}?${queryString}`);
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    updateRTMCycle = async (params: UpdateRTMCycle): Promise<Result<RTMCycle | RTMItem>> => {
        const { id, promotionStatus } = params;
        try {
            const result = await this.httpClient.post(
                `/v2/health/rtm-cycles/${id}/${promotionStatus.toLowerCase()}`,
                {},
            );
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    getPatientActivitySummary = async (
        patientRecordId: number,
        startDate: string,
        endDate: string,
    ): Promise<Result<ActivityData>> => {
        try {
            const result = await this.httpClient.get(
                `/v2/health/patients/${patientRecordId}/activity-report?start=${startDate}&end=${endDate}&groupBy=SIDE`,
            );
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    getMonthlyPatientActivityPdf = async (rtmMonthlySummaryId: number): Promise<Result<RTMPdfUrlDto>> => {
        try {
            const result = await this.httpClient.get(`/v2/health/rtm-monthly-summaries/${rtmMonthlySummaryId}/pdf-url`);
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    getPatientActivityPdf = async (rtmCycleId: number): Promise<Result<RTMPdfUrlDto>> => {
        try {
            const result = await this.httpClient.get(`/v2/health/rtm-cycles/${rtmCycleId}/pdf-url`);
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    getExerciseMetrics = async (): Promise<Result<ExerciseMetric[]>> => {
        try {
            const result = await this.httpClient.getExerciseService(
                `v3/exercise-metrics?completeness=COMPLETE&versionState=ALL`,
            );
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    getPublishedExercises = async (): Promise<Result<Exercise[]>> => {
        try {
            const result = await this.httpClient.getExerciseService(`v3/exercises?completeness=COMPLETE`);
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    getPROPatientMessages = async (id: number): Promise<Result<PROAnswersByQuestionsDict>> => {
        try {
            const result = await this.httpClient.get(`v2/patients/${id}/pros`);
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    getOrganizations = async (): Promise<Result<Organization[]>> => {
        try {
            const result = await this.httpClient.get(`v2/admin/organizations`);
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    getOrganizationAdmin = async (organizationId: number): Promise<Result<OrganizationAdmin>> => {
        try {
            const result = await this.httpClient.get(`v2/admin/organizations/${organizationId}`);
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    enableOrganizationAdmin = async (organizationId: number): Promise<Result<void>> => {
        try {
            const result = await this.httpClient.post(`v2/admin/organizations/${organizationId}/enable`, null);
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    disableOrganizationAdmin = async (organizationId: number): Promise<Result<void>> => {
        try {
            const result = await this.httpClient.post(`v2/admin/organizations/${organizationId}/disable`, null);
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    createOrganizationAdmin = async (organization: CreateOrganizationDto): Promise<Result<Organization>> => {
        try {
            const result = await this.httpClient.post(`v2/admin/organizations`, organization);
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    updateOrganizationAdmin = async (
        organizationId: number,
        organization: UpdateOrganizationAdminDto,
    ): Promise<Result<OrganizationAdmin>> => {
        try {
            const result = await this.httpClient.patch(`v2/admin/organizations/${organizationId}`, organization);
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    updateOrganization = async (
        organizationId: number,
        organization: UpdateOrganizationDto,
    ): Promise<Result<Organization>> => {
        try {
            const result = await this.httpClient.patch(`v2/organizations/${organizationId}`, organization);
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    adminGetUserByUUID = async (uuid: string): Promise<Result<UserProfile>> => {
        try {
            const result = await this.httpClient.get(`v2/admin/user-profile?uuid=${uuid}`);
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    adminGetScanModulesOrganizationOverride = async (): Promise<Result<{ organizationId: number }>> => {
        try {
            const result = await this.httpClient.get(`v2/admin/settings/scan-modules-organization-override`);
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    adminUpdateScanModulesOrganizationOverride = async (organizationId: number): Promise<Result<boolean>> => {
        try {
            const result = await this.httpClient.post(`v2/admin/settings/scan-modules-organization-override`, {
                organizationId,
            });
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    adminGetUserWithInviteCodeByEmail = async (email: string): Promise<Result<UserProfileWithInviteCode>> => {
        try {
            const result = await this.httpClient.get(`v2/admin/invite-code?email=${encodeURIComponent(email)}`);
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    adminGetPROTypeformForms = async (): Promise<Result<PROTypeformForm[]>> => {
        try {
            const result = await this.httpClient.get(`v2/admin/pro/typeform-forms/`);
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    adminGetPROTypeformUnprocessedResponses = async (): Promise<Result<PROTypeformResponse[]>> => {
        try {
            const result = await this.httpClient.get(`v2/admin/pro/unprocessed-typeform-responses/`);
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    adminCreatePROTypeformForm = async (proTypeFormDto: CreatePROTypeformFormDto): Promise<Result<PROTypeformForm>> => {
        try {
            const result = await this.httpClient.post(`v2/admin/pro/typeform-forms/`, proTypeFormDto);
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    adminGetClientVersions = async (): Promise<Result<ClientVersion[]>> => {
        try {
            const result = await this.httpClient.get(`v2/admin/scan/clients/`);
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    adminUpdateClientVersion = async (clientVersion: ClientVersion): Promise<Result<ClientVersion>> => {
        try {
            const result = await this.httpClient.put(`v2/admin/scan/clients/${clientVersion.versionId}`, {
                updateRequired: clientVersion.updateRequired,
            });
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    adminGetScanRiskFactorOutcomesByOrg = async (orgId: number): Promise<Result<AssessmentOutcome[]>> => {
        try {
            const result = await this.httpClient.get(`/v2/admin/scan/organizations/${orgId}/outcomes/risk-factors`);
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    adminPutScanRiskFactorForAssessmentAndOrg = async (
        orgId: number,
        assessmentId: string,
        outcome: AssessmentOutcome,
    ): Promise<Result<AssessmentOutcome>> => {
        try {
            const result = await this.httpClient.put(
                `/v2/admin/scan/organizations/${orgId}/assessments/${assessmentId}/outcomes/risk-factors`,
                outcome,
            );
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    getFavoriteExercises = async (): Promise<Result<string[]>> => {
        try {
            const result = await this.httpClient.get(`v2/users/meta/favorite-exercises/`);
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    addFavoriteExercise = async (exerciseId: string): Promise<Result<string[]>> => {
        try {
            const result = await this.httpClient.post(`v2/users/meta/favorite-exercises/`, { id: `${exerciseId}` });
            return result.data;
        } catch (e) {
            // If we get a 409, treat it as a success and fetch all favorites
            if (e.message.includes('exists')) {
                return this.getFavoriteExercises();
            }
            return await this.handleError(e);
        }
    };

    removeFavoriteExercise = async (exerciseId: string): Promise<Result<boolean>> => {
        try {
            const result = await this.httpClient.delete(`v2/users/meta/favorite-exercises/${exerciseId}`);
            return result.status === 204;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    // Provider Dashboard
    getProviderActions = async (): Promise<Result<DashboardData>> => {
        try {
            const result = await this.httpClient.get(`/v2/dashboards/actions`);
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    // Patient Interactions
    getAllPatientInteractions = async (patientRecordId: number): Promise<Result<PatientInteractionList>> => {
        try {
            const result = await this.httpClient.get(`v2/patients/${patientRecordId}/interactions`);
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    addPatientInteraction = async (patientRecordId: number, body: Interaction): Promise<Result<PatientInteraction>> => {
        try {
            const result = await this.httpClient.post(`v2/patients/${patientRecordId}/interactions`, body);
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    updatePatientInteraction = async (
        patientRecordId: number,
        interactionId: number,
        body: Interaction,
    ): Promise<Result<PatientInteraction>> => {
        try {
            const result = await this.httpClient.patch(
                `v2/patients/${patientRecordId}/interactions/${interactionId}`,
                body,
            );
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    // TODO does it return anything even a boolean?
    deletePatientInteraction = async (patientRecordId: number, interactionId: number): Promise<Result<boolean>> => {
        try {
            const result = await this.httpClient.delete(`v2/patients/${patientRecordId}/interactions/${interactionId}`);
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    uploadAdminOrgLogo = async (organizationId, file): Promise<Result<OrganizationLogoResponse>> => {
        try {
            const formData = new FormData();
            formData.append('file', file);
            const result = await this.httpClient.post(`v2/admin/organizations/${organizationId}/logo`, formData);
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    uploadOrgLogo = async (organizationId, file): Promise<Result<OrganizationLogoResponse>> => {
        try {
            const formData = new FormData();
            formData.append('file', file);
            const result = await this.httpClient.post(`v2/organizations/${organizationId}/logo`, formData);
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    deleteOrgLogo = async (organizationId): Promise<Result<boolean>> => {
        try {
            const result = await this.httpClient.delete(`v2/organizations/${organizationId}/logo`);
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    adminGetScanSessions = async (start: string, end: string): Promise<Result<ScanSessionAdmin[]>> => {
        try {
            const result = await this.httpClient.get(`v2/admin/scan/sessions?start=${start}&end=${end}`);
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    adminGetGaitSessions = async (start: string, end: string): Promise<Result<AdminGaitSession[]>> => {
        try {
            const result = await this.httpClient.get(`v2/admin/gait/sessions?start=${start}&end=${end}`);
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    adminGetSessionPoseLogSignedUrl = async (client: 'gait' | 'scan', sessionUUID: string): Promise<Result<string>> => {
        try {
            const result = await this.httpClient.get(`v2/admin/${client}/sessions/${sessionUUID}/pose-log/signed-url`);
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    adminGetAssessments = async (): Promise<Result<Assessment[]>> => {
        try {
            const result = await this.httpClient.get(`v2/scan/assessments`);
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    getExternalUrl = async <T>(url: string): Promise<Result<T>> => {
        try {
            const result = await axios.get(url);
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    postExternalUrl = async <T>(url: string, data: any): Promise<Result<T>> => {
        try {
            const result = await axios.post(url, data);
            return result.data;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    newPlanOfCare = async (
        patientRecordId: number,
        data: {
            injuredSideOfBody: string | null;
            injuredBodyPart: string | null;
            start: string;
        },
    ): Promise<Result<boolean>> => {
        try {
            await this.httpClient.post(`v2/patients/${patientRecordId}/plan-of-care`, data);
            return true;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    dischargePatient = async (patientRecordId: number, data: string): Promise<Result<boolean>> => {
        try {
            await this.httpClient.patch(`v2/patients/${patientRecordId}/plan-of-care`, { end: data });
            return true;
        } catch (e) {
            return await this.handleError(e);
        }
    };

    handleError = async (error): Promise<ErrorResult> => {
        if (error?.status === 0) {
            if (await this.isNetworkBlocked()) {
                Sentry.captureException(`Network block detected!`);
                this.httpClient.useFailOver();
            }
        }
        return { message: error.message, status: error.status };
    };

    /* If the cognito session is valid and we consistently get network unavailable errors
     when attempting health checks then the network is blocked
     */
    isNetworkBlocked = async (): Promise<boolean> => {
        const session = await Auth.currentSession();
        if (session.isValid()) {
            let attempts = 0;
            while (attempts < 3) {
                try {
                    attempts++;
                    await this.httpClient.get('healthcheck');
                    return false; // health check succeeded
                } catch (error) {
                    if (error.status !== 0) {
                        return false; // not a network unavailable error
                    }
                    await sleep(200);
                }
            }
            return true;
        }
        return false;
    };
}
