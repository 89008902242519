import { FC } from 'react';
import React from 'react';
import { SessionFeature, TrackedFeatureResultsDict } from '@exerai/vision-engine-web/dist/src/assessments/types';
import { SessionResultChartImageFeature } from '../types';
import { SessionResultsChart } from './SessionResultsChart';

interface SessionResultsChartsProps {
    trackedFeatureResults: TrackedFeatureResultsDict;
    onRenderChart: (id: number, imageData: SessionResultChartImageFeature) => void;
}

export const SessionResultsCharts: FC<SessionResultsChartsProps> = ({ trackedFeatureResults, onRenderChart }) => {
    const sessionResultsChartComponents = Object.values(trackedFeatureResults).map((tfr: SessionFeature) => {
        return <SessionResultsChart key={tfr.id} sessionFeature={tfr} onRenderChart={onRenderChart} />;
    });
    return <>{sessionResultsChartComponents || null}</>;
};
