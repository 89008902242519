import { useState, useEffect, useMemo, FC } from 'react';
import React from 'react';
import {
    useExerVision,
    AssessmentPhase,
    SessionResult,
    SessionResultDict,
    ResultUnit,
} from '@exerai/vision-engine-web';
import { ExecutableAssessment } from '@exerai/vision-engine-web/dist/src/assessments/types';
import { UnifiedKeypoints } from '@exerai/vision-engine-web/dist/src/common/pose/types';
import { AddOutlined, CancelOutlined, Error } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { Maybe, Nullish } from '@/common/types';
import { AddSyntheticResults } from './AddSyntheticResults/AddSyntheticResults';
import { ExcludeFrames } from './ExcludeFrames/ExcludeFrames';
import { SessionResultPose } from './SessionResultPose/SessionResultPose';
import { SessionResultsCharts } from './SessionResultsCharts/SessionResultsCharts';
import { SessionResultsPDFAction } from './SessionResultsPDF/SessionResultsPDFAction';
import { ResultActionsWrapper, SessionPoses, SessionResultsValues, SessionResultsWrapper } from './styles';
import { SessionParticipant, SessionResultChartImageFeature, SessionResultPoseImageResult } from './types';

interface SessionReplayProps {
    poseLog: UnifiedKeypoints[];
    imageSize: { width: number; height: number };
    assessmentDefinition: ExecutableAssessment;
    client: 'gait' | 'scan';
    participant: SessionParticipant;
    organizationImageUrl: Maybe<string>;
    sessionSpeed: Maybe<number>;
    capturedDate: string;
    assessmentId: string;
}

export const SessionResults: FC<SessionReplayProps> = (props: SessionReplayProps) => {
    const { poseLog, imageSize, assessmentDefinition, sessionSpeed } = props;
    const { session } = useExerVision();
    const { results, phase, assessment, setAssessment, processPoseLogAsSession, trackedFeatureResults, setPhase } =
        session;

    const poseImages = useMemo(() => {
        return new Map<number, SessionResultPoseImageResult>();
    }, []);
    const [chartImages, setChartImages] = useState<Map<number, SessionResultChartImageFeature>>(new Map());
    const [pdfReady, setPDFReady] = useState(false);
    const [isExcluding, setIsExcluding] = useState(false);
    const [isAddingSynthetic, setIsAddingSynthetic] = useState(false);
    const [excludedFrames, setExcludedFrames] = useState<Nullish<string>>();
    const [syntheticResults, setSyntheticResults] = useState<SessionResultDict>({});

    const onRenderPose = (resultId: number, data: SessionResultPoseImageResult) => {
        poseImages.set(resultId, data);
    };

    const onRenderChart = (chartId: number, data: SessionResultChartImageFeature) => {
        setChartImages(new Map(chartImages.set(chartId, data)));
    };

    const handleExcludeAction = () => {
        setIsExcluding(true);
    };

    const handleAddSyntheticAction = () => {
        setIsAddingSynthetic(true);
    };

    const fullResults: SessionResultDict = sessionSpeed
        ? {
            9999: { id: 9999, name: 'Speed', value: sessionSpeed, units: ResultUnit.METERS_SECOND, decimals: 2 },
            ...results,
        }
        : results;

    const frameResults = syntheticResults ? { ...results, ...syntheticResults } : results;

    const resultsSortedByFrameIndex = Object.values(frameResults).sort((r1: SessionResult, r2: SessionResult) => {
        return (r1.meta?.frameIndex || 0) - (r2.meta?.frameIndex || 0);
    });

    useEffect(() => {
        const resultsWithFrameIndexCount = resultsSortedByFrameIndex.filter((r) => {
            return r.meta?.frameIndex !== undefined;
        }).length;
        const trackedFeatureCount = Object.keys(trackedFeatureResults).length;
        if (poseImages.size >= resultsWithFrameIndexCount && chartImages.size >= trackedFeatureCount) {
            setPDFReady(true);
            return;
        }
        setPDFReady(false);
    }, [chartImages.size, poseImages, resultsSortedByFrameIndex, trackedFeatureResults]);

    useEffect((): void => {
        setPhase(AssessmentPhase.ASSESSING);
        poseImages.clear();
        processPoseLogAsSession(poseLog, undefined, excludedFrames || undefined);
    }, [excludedFrames, poseImages, poseLog, processPoseLogAsSession, setPhase, syntheticResults]);

    useEffect((): void => {
        setAssessment(assessmentDefinition);
    }, [assessmentDefinition, setAssessment]);

    return (
        <>
            {phase === AssessmentPhase.COMPLETE ? (
                <SessionResultsWrapper>
                    {isExcluding && (
                        <ExcludeFrames setIsExcluding={setIsExcluding} setExcludedFrames={setExcludedFrames} />
                    )}
                    {isAddingSynthetic && (
                        <AddSyntheticResults
                            setIsAddingSynthetic={setIsAddingSynthetic}
                            setSyntheticResults={setSyntheticResults}
                            setPhase={setPhase}
                        />
                    )}

                    {!isExcluding && !isAddingSynthetic && (
                        <>
                            <div>
                                <SessionResultsValues>
                                    {Object.values(fullResults).map((result: SessionResult) => {
                                        return (
                                            <div key={result.id}>
                                                <strong>{result.name}:</strong>{' '}
                                                {result.value?.toFixed(result.decimals || 2)} {result.units}
                                            </div>
                                        );
                                    })}
                                </SessionResultsValues>

                                <SessionPoses>
                                    {resultsSortedByFrameIndex.map((result: SessionResult) => {
                                        return result.meta?.frameIndex && poseLog ? (
                                            <SessionResultPose
                                                key={result.id}
                                                keypoints={poseLog[result.meta.frameIndex]}
                                                result={result}
                                                imageSize={imageSize}
                                                onRenderPose={onRenderPose}
                                            />
                                        ) : null;
                                    })}
                                </SessionPoses>
                                <SessionResultsCharts
                                    trackedFeatureResults={trackedFeatureResults}
                                    onRenderChart={onRenderChart}
                                />
                            </div>
                            <ResultActionsWrapper>
                                <IconButton onClick={handleAddSyntheticAction}>
                                    <AddOutlined />
                                </IconButton>
                                <IconButton onClick={handleExcludeAction}>
                                    <CancelOutlined />
                                </IconButton>
                                {assessment && pdfReady ? (
                                    <SessionResultsPDFAction
                                        poseImages={poseImages}
                                        chartImages={chartImages}
                                        results={fullResults}
                                        assessment={assessment}
                                        {...props}
                                    />
                                ) : (
                                    <Error />
                                )}
                            </ResultActionsWrapper>
                        </>
                    )}
                </SessionResultsWrapper>
            ) : (
                <>Loading...</>
            )}
        </>
    );
};
