import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from 'react';
import { AssessmentPhase, ResultUnit, SessionResultDict } from '@exerai/vision-engine-web';
import { Maybe } from '@exerai/vision-engine-web/dist/src/common/types';
import { AddOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { SubmitCancelButtons } from '@/components/common/Form/Form';
import { ResultModInputWrapper } from '../ExcludeFrames/styles';
import { SynthResultRow } from './SynthResultRow';
import { AddSyntheticResultActionWrapper } from './styles';
import { SynthResult } from './types';

interface AddSyntheticResultsProps {
    setIsAddingSynthetic: Dispatch<SetStateAction<boolean>>;
    setSyntheticResults: Dispatch<SetStateAction<SessionResultDict>>;
    setPhase: Dispatch<SetStateAction<Maybe<AssessmentPhase>>>;
}

export const AddSyntheticResults: FC<AddSyntheticResultsProps> = ({
    setIsAddingSynthetic,
    setSyntheticResults,
    setPhase,
}) => {
    const [synthResults, setSynthResults] = useState<SynthResult[]>([
        { id: crypto.randomUUID(), name: '', frameIndex: '' },
    ]);
    const [s2sDisabled, sets2sDisabled] = useState(true);

    const handleAdd = () => {
        setSynthResults([...synthResults, { id: crypto.randomUUID(), name: '', frameIndex: '' }]);
    };

    const handleRemove = (id) => {
        setSynthResults(synthResults.filter((r) => r.id !== id));
    };

    const handles2sSubmit = useCallback(() => {
        const syntheticResults = {};
        synthResults.forEach((sr, i) => {
            syntheticResults[i + 1] = {
                id: [i + 1],
                name: sr.name,
                value: undefined,
                units: '' as unknown as ResultUnit,
                decimals: 0,
                meta: {
                    frameIndex: +sr.frameIndex,
                },
            };
        });
        setSyntheticResults(syntheticResults);
        setPhase(AssessmentPhase.COMPLETE);
        setIsAddingSynthetic(false);
    }, [synthResults, setIsAddingSynthetic, setPhase, setSyntheticResults]);

    const inputComponents = synthResults.map((r) => {
        return <SynthResultRow key={r.id} r={r} setSynthResults={setSynthResults} handleRemove={handleRemove} />;
    });

    useEffect(() => {
        if (
            synthResults
                .map((r) => {
                    return !!r.name && !!r.frameIndex;
                })
                .every((bool) => bool)
        ) {
            sets2sDisabled(false);
            return;
        }
        sets2sDisabled(true);
    }, [synthResults]);

    return (
        <div>
            <h3>Mark Important Frames From Log</h3>
            <p>
                Enter the frame index shown in the replay to mark that frame as important. Result Title will be shown
                above the frame in the generated PDF.{' '}
            </p>
            <ResultModInputWrapper>{inputComponents}</ResultModInputWrapper>
            <AddSyntheticResultActionWrapper>
                <IconButton color="primary" onClick={handleAdd}>
                    <AddOutlined />
                </IconButton>
            </AddSyntheticResultActionWrapper>
            <SubmitCancelButtons
                cancelFunction={() => {
                    setIsAddingSynthetic(false);
                }}
                submitFunction={handles2sSubmit}
                submitDisabled={s2sDisabled}
            />
        </div>
    );
};
