import React, { useEffect, useState } from 'react';
import { ExerVisionProvider } from '@exerai/vision-engine-web';
import { DateTime } from 'luxon';
import { validate } from 'uuid';
import { Products } from '@/common/const';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { Maybe, Nullish } from '@/common/types';
import { SessionResultsContainer } from '@/components/Admin/SessionResults/SessionResultsContainer';
import { SessionParticipant } from '@/components/Admin/SessionResults/types';
import { SessionReplayAndResults } from '@/components/Scan/Replay/SessionReplayAndResults';
import { SessionReplayAction } from '@/components/common/Pose/SessionReplayAction';
import { SessionResultsAction } from '@/components/common/Pose/SessionResultsAction';
import { TableActionsWrapper } from '@/components/common/Table/styles';
import { resultIsError } from '@/services/HttpService';
import { AdminGaitTable } from './AdminGaitTable';
import { GaitSessionPoseLogAction } from './GaitSessionPoseLogAction';

interface AdminGaitSessionRow {
    id: number;
    sessionUUID: string;
    capturedDate: string;
    action: string;
    participant: SessionParticipant;
    organizationImageUrl: Nullish<string>;
    sessionSpeed?: Maybe<number>;
}

export const AdminGaitTableContainer = () => {
    const { httpService } = useHttpContext();
    const [rows, setRows] = useState<AdminGaitSessionRow[]>([]);

    const getSessions = async () => {
        const res = await httpService.adminGetGaitSessions(
            DateTime.now().minus({ days: 90 }).toISO(),
            DateTime.now().toISO(),
        );
        if (!res || resultIsError(res)) {
            alert('Couldnt fetch gait sessions :(');
        } else {
            setRows(
                res
                    .map(
                        ({
                            id,
                            capturedDate,
                            sessionUUID,
                            patient,
                            patientRecordGaitUid,
                            practitioner,
                            organization,
                            session,
                        }) => {
                            return {
                                id,
                                capturedDate,
                                sessionUUID,
                                patientName: `${patient.firstName} ${patient.lastName}`,
                                action: sessionUUID,
                                assessmentId: 'AST-0024',
                                participant: {
                                    uid: patientRecordGaitUid,
                                    practitionerName: `${practitioner.firstName} ${practitioner.lastName}`,
                                },
                                organizationImageUrl: organization.logoUrl,
                                sessionSpeed: session.data
                                    .find((d) => d.item === 'speed')
                                    ?.measures.find((measure) => measure.name === 'avgSpeed')?.value,
                            };
                        },
                    )
                    .filter((row) => {
                        return validate(row.sessionUUID);
                    }),
            );
        }
    };

    useEffect(() => {
        (async () => {
            await getSessions();
        })();
    }, []);

    const columnStructure = [
        {
            Header: 'ID',
            accessor: 'id',
            width: '10%',
            Cell: ({ value }) => <>{value}</>,
        },
        {
            Header: 'Captured Date',
            accessor: 'capturedDate',
            width: '15%',
            Cell: ({ value }) => <>{value}</>,
        },
        {
            Header: 'Patient Name',
            accessor: 'patientName',
            width: '15%',
            Cell: ({ value }) => <>{value}</>,
        },
        {
            Header: 'UUID',
            accessor: 'sessionUUID',
            width: '20%',
            Cell: ({ value }) => <>{value}</>,
        },
        {
            Header: 'Action',
            accessor: 'action',
            width: '10%',
            Cell: ({ value, row }) => (
                <TableActionsWrapper>
                    <SessionReplayAction product={Products.GAIT}>
                        <ExerVisionProvider>
                            <SessionReplayAndResults
                                client="gait"
                                sessionUUID={value}
                                assessmentId={row.original.assessmentId}
                            />
                        </ExerVisionProvider>
                    </SessionReplayAction>
                    <SessionResultsAction product={Products.GAIT}>
                        <ExerVisionProvider>
                            <SessionResultsContainer
                                client="gait"
                                sessionUUID={value}
                                participant={row.original.participant}
                                organizationImageUrl={row.original.organizationImageUrl}
                                sessionSpeed={row.original.sessionSpeed}
                                capturedDate={row.original.capturedDate}
                                assessmentID="AST-0024"
                            />
                        </ExerVisionProvider>
                    </SessionResultsAction>
                    <GaitSessionPoseLogAction sessionUUID={value} />
                </TableActionsWrapper>
            ),
        },
    ];

    return <AdminGaitTable columnStructure={columnStructure} data={rows} />;
};
