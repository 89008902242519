import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { Nullish } from '@exerai/vision-engine-web/dist/src/common/types';
import { TextField, Button } from '@mui/material';
import { ResultModInputWrapper } from './styles';

interface ExcludeFramesProps {
    setIsExcluding: Dispatch<SetStateAction<boolean>>;
    setExcludedFrames: Dispatch<SetStateAction<Nullish<string>>>;
}
export const ExcludeFrames: FC<ExcludeFramesProps> = ({ setIsExcluding, setExcludedFrames }) => {
    const [candidateExcludeFrames, setCandidateExcludeFrames] = useState<Nullish<string>>();

    const handleSubmitExcluded = () => {
        setCandidateExcludeFrames(null);
        setExcludedFrames(candidateExcludeFrames);
        setIsExcluding(false);
    };

    const handleCancelExcluded = () => {
        setCandidateExcludeFrames(null);
        setIsExcluding(false);
    };

    return (
        <div>
            <ResultModInputWrapper>
                <TextField
                    placeholder="Frames to exclude from processing: e.g. '2-10,200,400-401' "
                    type="text"
                    value={candidateExcludeFrames || ''}
                    style={{ width: 600 }}
                    onChange={(e) => {
                        setCandidateExcludeFrames(e.target.value);
                    }}
                />
            </ResultModInputWrapper>
            <Button variant="secondary" onClick={handleCancelExcluded}>
                Cancel
            </Button>
            <Button disabled={!candidateExcludeFrames} onClick={handleSubmitExcluded}>
                Exclude
            </Button>
        </div>
    );
};
