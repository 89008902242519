import React, { FC, useEffect, useRef } from 'react';
import { useExerVision, SessionResult, AssessmentPhase, PoseLogPhases } from '@exerai/vision-engine-web';
import { ExecutableAssessment } from '@exerai/vision-engine-web/dist/src/assessments/types';
import { UnifiedKeypoints } from '@exerai/vision-engine-web/dist/src/common/pose/types';
import { PauseCircle, PlayCircle, SkipNextOutlined, SkipPreviousOutlined } from '@mui/icons-material';
import { Grid, IconButton } from '@mui/material';
import { Maybe } from '@/common/types';
import { SessionReplayCanvasWrapper } from '@/components/common/Pose/styles';
import { ReplaySlider } from './ReplaySlider/ReplaySlider';
import { getDisplayedEdges, getDisplayedKeypoints } from './pose/utils';
import {
    SessionReplayCurrentFrame,
    SessionReplayHeader,
    SessionReplayPhaseLabel,
    SessionReplayPlayControls,
} from './styles';

interface SessionReplayProps {
    poseDimensions: { width: number; height: number };
    poseLog: UnifiedKeypoints[];
    phasesFromLog: Maybe<PoseLogPhases>;
    assessmentDefinition: ExecutableAssessment;
}

const REPLAY_SCALE = 1;

export const SessionReplay: FC<SessionReplayProps> = ({
    poseDimensions,
    poseLog,
    phasesFromLog,
    assessmentDefinition,
}) => {
    const { session, outputCanvasRef, draw, frame, setFrame, setKeypoints, setEdges, isPlaying, handlePlay } =
        useExerVision();
    const { phase, setPhase, setAssessment, frameFeatureResults, results } = session;

    const isDelayedRef = useRef(false);

    const DEFAULT_FPS = 30;

    useEffect(() => {
        setAssessment(assessmentDefinition);
        handlePlay(true);
    }, [assessmentDefinition, handlePlay, setAssessment]);

    useEffect(() => {
        if (isDelayedRef.current === true || !poseLog || poseLog.length === 0) {
            return;
        }
        const activePhase = phasesFromLog?.reduce((high, curr) =>
            curr.value > high.value && frame >= curr.value ? curr : high,
        );
        if (activePhase) {
            setPhase(activePhase.phase);
        }

        if (frame >= poseLog.length) {
            setPhase(AssessmentPhase.COMPLETE);
            handlePlay(false);
        }
        if (poseLog[frame]) {
            setKeypoints(getDisplayedKeypoints(poseLog[frame]));
            setEdges(getDisplayedEdges(poseLog[frame]));
        }

        if (isPlaying) {
            if (DEFAULT_FPS) {
                isDelayedRef.current = true;
                setTimeout(() => {
                    isDelayedRef.current = false;
                    setFrame((f) => f + 1);
                }, 1000 / DEFAULT_FPS);
            } else {
                setFrame((prevFrame) => {
                    return prevFrame + 1;
                });
            }
        }
    }, [frame, handlePlay, isPlaying, phase, phasesFromLog, poseLog, setEdges, setFrame, setKeypoints, setPhase]);

    useEffect(() => {
        draw(REPLAY_SCALE, '#2dc1bb', '#2dc1bb');
    }, [draw, frame]);

    const handleSlider = (_, frame) => {
        setFrame(frame);
        handlePlay(false);
    };

    const handlePrevClick = () => {
        setFrame((c) => c - 1);
        handlePlay(false);
    };

    const handleNextClick = () => {
        setFrame((c) => c + 1);
        handlePlay(false);
    };

    return (
        <>
            <SessionReplayHeader>
                <SessionReplayPhaseLabel>
                    {phasesFromLog ? (
                        <>
                            Phase: <span>{phase}</span>
                        </>
                    ) : null}
                </SessionReplayPhaseLabel>
                <SessionReplayCurrentFrame>
                    {poseLog && frame !== undefined ? `Frame: ${frame} / ${poseLog.length}` : 'Loading'}
                </SessionReplayCurrentFrame>
            </SessionReplayHeader>

            <Grid container>
                <Grid item sm={10}>
                    <SessionReplayCanvasWrapper width={'100%'} height={'100%'}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <canvas
                                ref={outputCanvasRef}
                                width={poseDimensions.width / (1 / REPLAY_SCALE)}
                                height={poseDimensions.height / (1 / REPLAY_SCALE)}
                            />
                        </div>
                    </SessionReplayCanvasWrapper>
                </Grid>
                <Grid item sm={2}>
                    <div style={{ paddingLeft: 12, fontSize: 14 }}>
                        <h4 style={{ marginTop: 0 }}>Real-Time Results</h4>
                        {Object.values(results).map((result: SessionResult) => {
                            return (
                                <div key={result.id}>
                                    <strong>{result.name}:</strong> {result.value?.toFixed(2)} {result.units}{' '}
                                    {result.meta?.frameIndex && ` (${result.meta.frameIndex})`}
                                </div>
                            );
                        })}
                        <div>--</div>
                        {frameFeatureResults &&
                            Object.values(frameFeatureResults).map((result: SessionResult) => {
                                return (
                                    <div key={result.id}>
                                        <strong>{result.name}:</strong> {result.value?.toFixed(2)} {result.units}
                                    </div>
                                );
                            })}
                    </div>
                </Grid>
            </Grid>

            <SessionReplayPlayControls>
                <div>
                    <IconButton
                        aria-label="prev"
                        onClick={() => handlePrevClick()}
                        edge="end"
                        style={{ color: '#666666', fontSize: 36 }}
                    >
                        <SkipPreviousOutlined fontSize="inherit" />
                    </IconButton>

                    <IconButton
                        aria-label="play/pause"
                        onClick={() => handlePlay()}
                        edge="end"
                        style={{ color: '#666666', fontSize: 36 }}
                    >
                        {isPlaying ? <PauseCircle fontSize="inherit" /> : <PlayCircle fontSize="inherit" />}
                    </IconButton>

                    <IconButton
                        aria-label="prev"
                        onClick={() => handleNextClick()}
                        edge="end"
                        style={{ color: '#666666', fontSize: 36 }}
                    >
                        <SkipNextOutlined fontSize="inherit" />
                    </IconButton>
                </div>
            </SessionReplayPlayControls>
            <ReplaySlider
                phases={phasesFromLog}
                frame={frame}
                frameCount={poseLog?.length}
                handleSlider={handleSlider}
            />
        </>
    );
};
