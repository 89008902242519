import { Edges, Edge, RenderJoint, unifiedBodyDefaultDrawEdges } from '@exerai/vision-engine-web';
import { UnifiedKeypoints } from '@exerai/vision-engine-web/dist/src/common/pose/types';
import { initKeypoints } from './keypoints';

export const getDisplayedKeypoints = (detectedKeypoints: UnifiedKeypoints): UnifiedKeypoints => {
    const renderKeypoints: UnifiedKeypoints = {} as UnifiedKeypoints;
    Object.entries(detectedKeypoints).forEach((entry) => {
        const [key, joint] = entry as [string, RenderJoint];
        const renderJoint: RenderJoint = {
            dsId: initKeypoints[key].dsId,
            name: initKeypoints[key].name,
            hexColor: initKeypoints[key].hexColor,
            hidden: initKeypoints[key].hidden,
            coordinates: joint.coordinates,
            confidence: joint.confidence,
        };
        renderKeypoints[key] = renderJoint;
    });
    return renderKeypoints;
};

export const getDisplayedEdges = (keypoints: UnifiedKeypoints): Edges => {
    const renderEdges = {} as Edges;
    if (!keypoints) {
        return renderEdges;
    }
    Object.entries(unifiedBodyDefaultDrawEdges).forEach((entry) => {
        const [key, edge] = entry;
        let hidden = edge.hidden;
        const con1 = keypoints[edge.jointIds[0]].confidence || 0;
        const con2 = keypoints[edge.jointIds[1]].confidence || 0;
        if (con1 < 0.5 && con2 < 0.5) {
            hidden = true;
        }
        const renderEdge: Edge = {
            dsId: edge.dsId,
            name: edge.name,
            joints: [keypoints[edge.jointIds[0]], keypoints[edge.jointIds[1]]],
            hexColor: edge.hexColor,
            hidden,
        };
        renderEdges[key] = renderEdge;
    });
    return renderEdges;
};
