import React, { Dispatch, FC, SetStateAction } from 'react';
import { RemoveOutlined } from '@mui/icons-material';
import { TextField, IconButton } from '@mui/material';
import { SynthResultRemoveAction, SynthResultRowWrapper } from './styles';
import { SynthResult } from './types';

interface SynthResultRowProps {
    r: SynthResult;
    setSynthResults: Dispatch<SetStateAction<SynthResult[]>>;
    handleRemove: (id: any) => void;
}

export const SynthResultRow: FC<SynthResultRowProps> = ({ r, setSynthResults, handleRemove }) => {
    const frameIndexIsPositiveNumberOrEmpty = (field: string, value: string): boolean => {
        if (field === 'frameIndex' && value) {
            if (!/^\d+$/.test(value)) {
                return false;
            }
            const numValue = parseInt(value, 10);
            if (!(numValue > 0)) {
                return false;
            }
        }
        return true;
    };

    const onChange = (id: string, field: string, value: string) => {
        if (!frameIndexIsPositiveNumberOrEmpty(field, value)) {
            return;
        }
        setSynthResults((synthResults) => synthResults.map((r) => (r.id === id ? { ...r, [field]: value } : r)));
    };

    return (
        <SynthResultRowWrapper>
            <TextField
                placeholder="Result Title"
                type="text"
                value={r.name || ''}
                onChange={(e) => {
                    onChange(r.id, 'name', e.target.value);
                }}
            />
            <TextField
                placeholder="Frame Index"
                type="text"
                value={r.frameIndex || ''}
                onChange={(e) => {
                    onChange(r.id, 'frameIndex', e.target.value);
                }}
            />
            <SynthResultRemoveAction>
                <IconButton onClick={() => handleRemove(r.id)}>
                    <RemoveOutlined />
                </IconButton>
            </SynthResultRemoveAction>
        </SynthResultRowWrapper>
    );
};
