import { styled } from '@mui/material';

export const SynthResultRowWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
}));

export const AddSyntheticResultActionWrapper = styled('div')(({ theme }) => ({
    marginBottom: theme.spacing(2),
}));

export const SynthResultRemoveAction = styled('div')(({ theme }) => ({
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
}));
