import React, { ReactNode } from 'react';
import { Assessment, SessionResult, SessionResultDict } from '@exerai/vision-engine-web';
import { StyleSheet, Text, View, Document, Page, Image } from '@react-pdf/renderer';
import { DateTime } from 'luxon';
import { ExerPDFHeader } from '@/common/pdf/ExerPDFHeader';
import { Maybe } from '@/common/types';
import { convertPxToPt } from '@/components/Health/PatientSessions/SessionsPDF/SessionsPDFUtils';
import { SessionParticipant, SessionResultChartImageFeature, SessionResultPoseImageResult } from '../types';
import { pdfStyles } from './pdfStyles';

interface SessionResultsPDFDocumentProps {
    results: SessionResultDict;
    assessment: Assessment;
    participant: SessionParticipant;
    organizationImageUrl: Maybe<string>;
    capturedDate: string;
    poseImages: Map<number, SessionResultPoseImageResult>;
    chartImages: Map<number, SessionResultChartImageFeature>;
}

export const SessionResultsPDFDocument = (props: SessionResultsPDFDocumentProps) => {
    const { results, assessment, participant, organizationImageUrl, capturedDate, poseImages, chartImages } = props;
    const sessionDate = DateTime.fromISO(capturedDate).toFormat('L/dd/yyyy');

    const styles = StyleSheet.create(pdfStyles);
    const resultRows: SessionResult[][] = [];
    Object.values(results as SessionResult[]).forEach((result, i) => {
        const topIndex = Math.floor(i / 2);
        const t = resultRows[topIndex] || [];
        t.push(result);
        resultRows[topIndex] = t;
    });

    const poseImageRows: ReactNode[][] = [];
    poseImages.forEach((poseImage, i) => {
        const topIndex = Math.floor(i / 5);
        const t = poseImageRows[topIndex] || [];
        t.push(
            <View
                style={{
                    border: '1px solid #666666',
                    width: 120,
                    borderRadius: 6,
                    marginRight: 10,
                }}
            >
                <Text style={{ fontSize: 6, paddingTop: 4, textAlign: 'center' }}>
                    <Text style={pdfStyles.resultName}>
                        {poseImage.result.name}
                        {poseImage.result.value ? ': ' : ''}:{' '}
                    </Text>
                    {poseImage.result.value?.toFixed(1)}
                    {poseImage.result.units === '°' ? poseImage.result.units : ` ${poseImage.result.units}`}
                </Text>
                <Image src={poseImage.image} />
            </View>,
        );
        poseImageRows[topIndex] = t;
    });

    const chartImageRows: ReactNode[][] = [];
    chartImages.forEach((ci, i) => {
        const topIndex = Math.floor(i / 2);
        const t = chartImageRows[topIndex] || [];
        t.push(
            <View
                key={ci.image}
                style={{
                    position: 'relative',
                    textAlign: 'center',
                    marginBottom: 16,
                }}
                wrap={false}
            >
                <View style={{ fontSize: convertPxToPt(12), textAlign: 'center', width: '45%' }}>
                    <Text>{ci.feature.name}</Text>
                </View>
                <Image src={ci.image} style={{ height: 125, marginLeft: 'auto', marginRight: 'auto' }} />
            </View>,
        );
        chartImageRows[topIndex] = t;
    });

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <ExerPDFHeader organizationLogoPrintUrl={organizationImageUrl} />
                <View style={styles.subHeaderWrapper}>
                    <View style={styles.subHeaderRow}>
                        <View style={styles.subHeaderTextWrapper}>
                            <Text style={styles.bold}>Participant ID: </Text>
                            <Text>{participant.uid}</Text>
                        </View>
                        <View style={styles.subHeaderTextWrapper}>
                            <Text style={styles.bold}>Provider: </Text>
                            <Text>{participant.practitionerName}</Text>
                        </View>
                    </View>
                    <View style={[styles.subHeaderRow, {}]}>
                        <View style={styles.subHeaderTextWrapper}>
                            <Text style={styles.bold}>Note: </Text>
                            <Text style={{ color: '#ffffff', borderBottom: '1px solid #999999' }}>
                                {' '}
                                ________________________
                            </Text>
                        </View>
                        <View style={[styles.subHeaderTextWrapper]}>
                            <Text style={styles.bold}>Date: </Text>
                            <Text> {sessionDate}</Text>
                        </View>
                    </View>
                </View>
                <View style={styles.sectionHeader}>
                    <Text>{assessment.name}</Text>
                </View>
                <View style={styles.sectionText}>
                    <Text>{assessment.purpose}</Text>
                </View>
                {resultRows.map((resultRow, i) => {
                    return (
                        <View key={resultRow[0].id} style={styles.resultRow}>
                            {resultRow.map((result, i) => {
                                return (
                                    <View
                                        key={result.id}
                                        style={[
                                            styles.resultBox,
                                            { marginRight: i === 0 && resultRow.length > 1 ? 12 : 0 },
                                        ]}
                                    >
                                        <Text style={styles.resultHeader}>{result.name}</Text>
                                        <View style={styles.result}>
                                            <Text style={styles.resultValue}>
                                                {result.value?.toFixed(result.decimals || 2)}
                                            </Text>
                                            <Text
                                                style={[
                                                    styles.resultUnit,
                                                    { fontSize: result.units === '°' ? 24 : 16 },
                                                ]}
                                            >
                                                {result.units}
                                            </Text>
                                        </View>
                                    </View>
                                );
                            })}
                        </View>
                    );
                })}
            </Page>
            <Page size="A4" style={styles.page}>
                <ExerPDFHeader organizationLogoPrintUrl={organizationImageUrl} />
                {poseImageRows.map((pir) => {
                    return (
                        <View key={pir[0]?.toString()} style={styles.resultRow}>
                            {pir.map((pi) => pi)}
                        </View>
                    );
                })}
            </Page>
            <Page size="A4" style={styles.page}>
                <ExerPDFHeader organizationLogoPrintUrl={organizationImageUrl} />
                {chartImageRows.map((cir) => {
                    return (
                        <View key={cir[0]?.toString()} style={{ ...styles.resultRow }}>
                            {cir.map((ci) => ci)}
                        </View>
                    );
                })}
            </Page>
        </Document>
    );
};
