import ReactPDF from '@react-pdf/renderer';

export const pdfStyles: ReactPDF.Styles = {
    page: {
        fontSize: 10,
        backgroundColor: '#ffffff',
        padding: 24,
        fontFamily: 'DM Sans',
    },
    bold: {
        fontWeight: 'bold',
    },
    headerWrapper: {
        width: '100%',
        borderBottom: '1px solid #dadce6',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingBottom: 12,
    },
    subHeaderWrapper: {
        width: '100%',
        borderBottom: '1px solid #dadce6',
        paddingTop: 8,
        paddingBottom: 8,
    },
    subHeaderRow: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 4,
    },
    subHeaderTextWrapper: {
        display: 'flex',
        flexDirection: 'row',
    },
    sectionHeader: {
        fontSize: 16,
        fontWeight: 'bold',
        marginTop: 12,
        marginBottom: 4,
    },
    sectionText: {
        fontSize: 10,
        marginBottom: 12,
    },
    resultRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingBottom: 12,
    },
    resultRows: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        paddingBottom: 8,
    },
    resultBox: {
        border: '1px solid #666666',
        borderRadius: 4,
        flexBasis: '100%',
        flex: 1,
        padding: 8,
        paddingBottom: 0,
    },
    resultHeader: {
        fontWeight: 'bold',
    },
    result: {
        display: 'flex',
        flexDirection: 'row',
        alignSelf: 'center',
    },
    resultValue: {
        fontSize: 32,
        fontWeight: 'bold',
        color: '#00b4ae',
    },
    resultUnit: {
        fontSize: 16,
        fontWeight: 'normal',
        paddingLeft: 4,
        paddingTop: 4,
    },
};
