import { useState, useEffect, FC } from 'react';
import React from 'react';
import {
    poseLogIsV1,
    convertV1PoseLog,
    poseLogIsV2,
    convertV2PoseLog,
    PoseLogV1Schema,
    PoseLogV2Schema,
    ExerPoseBodyAccessor,
    MediaPipePoseBodyAccessor,
} from '@exerai/vision-engine-web';
import { ExecutableAssessment } from '@exerai/vision-engine-web/dist/src/assessments/types';
import { UnifiedKeypoints } from '@exerai/vision-engine-web/dist/src/common/pose/types';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { Maybe, Nullish } from '@/common/types';
import { LoadingSpinner } from '@/components/common/LoadingSpinner/LoadingSpinner';
import { useAssessmentsContext } from '@/components/context/hooks/AssessmentsContext';
import { resultIsError, Result } from '@/services/HttpService';
import { SessionResults } from './SessionResults';
import { SessionParticipant } from './types';

interface SessionReplayProps {
    sessionUUID: string;
    client: 'gait' | 'scan';
    participant: SessionParticipant;
    organizationImageUrl: Maybe<string>;
    sessionSpeed: Maybe<number>;
    capturedDate: string;
    assessmentID: string;
}

export const SessionResultsContainer: FC<SessionReplayProps> = (props: SessionReplayProps) => {
    const { sessionUUID, client, assessmentID } = props;
    const { httpService } = useHttpContext();
    const { assessmentsDict } = useAssessmentsContext();
    const [assessmentDefinition, setAssessmentDefinition] = useState<ExecutableAssessment>();
    const [poseLog, setPoseLog] = useState<Nullish<UnifiedKeypoints[]>>();
    const [imageSize, setImageSize] = useState<{ width: number; height: number }>({ width: 480, height: 600 });

    useEffect(() => {
        (async () => {
            const res = await httpService.adminGetSessionPoseLogSignedUrl(client, sessionUUID);
            if (resultIsError(res)) {
                alert(res.message);
                return;
            }
            const poseRes: Result<PoseLogV1Schema | PoseLogV2Schema> = await httpService.getExternalUrl(res);
            if (resultIsError(poseRes)) {
                alert(poseRes.message);
                return;
            }
            let convertedPoseLog: UnifiedKeypoints[];
            let bodyAccessor = ExerPoseBodyAccessor;
            if ('meta' in poseRes) {
                if (poseRes.meta?.poseModel?.name === 'mediapipe-landmark') {
                    bodyAccessor = MediaPipePoseBodyAccessor;
                }
            }
            if (poseLogIsV1(poseRes)) {
                convertedPoseLog = convertV1PoseLog(poseRes, bodyAccessor);
                setPoseLog(convertedPoseLog);
            } else if (poseLogIsV2(poseRes)) {
                convertedPoseLog = convertV2PoseLog(poseRes, bodyAccessor);
                setImageSize({ width: poseRes.image_shape[1], height: poseRes.image_shape[0] });
                setPoseLog(convertedPoseLog);
            }
            assessmentsDict &&
                assessmentsDict[assessmentID].directives &&
                setAssessmentDefinition(assessmentsDict[assessmentID] as ExecutableAssessment);
        })();
    }, [assessmentID, assessmentsDict, client, httpService, sessionUUID]);

    return assessmentDefinition && poseLog ? (
        <SessionResults
            poseLog={poseLog}
            imageSize={imageSize}
            assessmentDefinition={assessmentDefinition}
            client={client}
            participant={props.participant}
            sessionSpeed={props.sessionSpeed}
            capturedDate={props.capturedDate}
            assessmentId={props.assessmentID}
            organizationImageUrl={props.organizationImageUrl}
        />
    ) : (
        <LoadingSpinner />
    );
};
