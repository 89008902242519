import { Joint, UnifiedBodyJoints } from '@exerai/vision-engine-web';

export const initKeypoints: { [key in UnifiedBodyJoints]: Joint } = {
    [UnifiedBodyJoints.ANKLE_RIGHT]: {
        dsId: 'Rankle',
        name: 'Right Ankle',
        hexColor: '#2dc1bb',
        hidden: false,
    },
    [UnifiedBodyJoints.KNEE_RIGHT]: {
        dsId: 'Rknee',
        name: 'Right Knee',
        hexColor: '#2dc1bb',
        hidden: false,
    },
    [UnifiedBodyJoints.HIP_RIGHT]: {
        dsId: 'Rhip',
        name: 'Right Hip',
        hexColor: '#2dc1bb',
        hidden: false,
    },
    [UnifiedBodyJoints.HIP_LEFT]: {
        dsId: 'Lhip',
        name: 'Left Hip',
        hexColor: '#2dc1bb',
        hidden: false,
    },
    [UnifiedBodyJoints.KNEE_LEFT]: {
        dsId: 'Lknee',
        name: 'Left Knee',
        hexColor: '#2dc1bb',
        hidden: false,
    },
    [UnifiedBodyJoints.ANKLE_LEFT]: {
        dsId: 'Lankle',
        name: 'Left Ankle',
        hexColor: '#2dc1bb',
        hidden: false,
    },
    [UnifiedBodyJoints.NECK]: {
        dsId: 'Neck',
        name: 'Neck',
        hexColor: '#2dc1bb',
        hidden: false,
    },
    // '7': {
    //     dsId: 'Head',
    //     name: 'Head',
    //     hexColor: '#2dc1bb',
    //     hidden: false,
    //     coordinates: {
    //         x: 408,
    //         y: 87.5,
    //     },
    //     confidence: 0.9990245997905731,
    // },
    [UnifiedBodyJoints.WRIST_RIGHT]: {
        dsId: 'Rwrist',
        name: 'Right Wrist',
        hexColor: '#2dc1bb',
        hidden: false,
    },
    [UnifiedBodyJoints.ELBOW_RIGHT]: {
        dsId: 'Relbow',
        name: 'Right Elbow',
        hexColor: '#2dc1bb',
        hidden: false,
    },
    [UnifiedBodyJoints.SHOULDER_RIGHT]: {
        dsId: 'Rshoulder',
        name: 'Right Shoulder',
        hexColor: '#2dc1bb',
        hidden: false,
    },
    [UnifiedBodyJoints.SHOULDER_LEFT]: {
        dsId: 'Lshoulder',
        name: 'Left Shoulder',
        hexColor: '#2dc1bb',
        hidden: false,
    },
    [UnifiedBodyJoints.ELBOW_LEFT]: {
        dsId: 'Lelbow',
        name: 'Left Elbow',
        hexColor: '#2dc1bb',
        hidden: false,
    },
    [UnifiedBodyJoints.WRIST_LEFT]: {
        dsId: 'Lwrist',
        name: 'Left Wrist',
        hexColor: '#2dc1bb',
        hidden: false,
    },
    [UnifiedBodyJoints.NOSE]: {
        dsId: 'Nose',
        name: 'Nose',
        hexColor: '#2dc1bb',
        hidden: false,
    },
    [UnifiedBodyJoints.EYE_RIGHT]: {
        dsId: 'Reye',
        name: 'Right Eye',
        hexColor: '#2dc1bb',
        hidden: false,
    },
    [UnifiedBodyJoints.EYE_LEFT]: {
        dsId: 'Leye',
        name: 'Left Eye',
        hexColor: '#2dc1bb',
        hidden: false,
    },
    [UnifiedBodyJoints.EAR_RIGHT]: {
        dsId: 'Rear',
        name: 'Right Ear',
        hexColor: '#2dc1bb',
        hidden: false,
    },
    [UnifiedBodyJoints.EAR_LEFT]: {
        dsId: 'Lear',
        name: 'Left Ear',
        hexColor: '#2dc1bb',
        hidden: false,
    },
    [UnifiedBodyJoints.TOE_RIGHT]: {
        dsId: 'RightBigToe',
        name: 'Right Big Toe',
        hexColor: '#2dc1bb',
        hidden: false,
    },
    [UnifiedBodyJoints.HEEL_RIGHT]: {
        dsId: 'RightHeel',
        name: 'Right Heel',
        hexColor: '#2dc1bb',
        hidden: false,
    },
    [UnifiedBodyJoints.HEEL_LEFT]: {
        dsId: 'LeftHeel',
        name: 'Left Heel',
        hexColor: '#2dc1bb',
        hidden: false,
    },
    [UnifiedBodyJoints.TOE_LEFT]: {
        dsId: 'LeftBigToe',
        name: 'Left Big Toe',
        hexColor: '#2dc1bb',
        hidden: false,
    },
    [UnifiedBodyJoints.HIPS_MID]: {
        dsId: 'MidHips',
        name: 'Mid Hips',
        hexColor: '#2dc1bb',
        hidden: false,
    },
};
