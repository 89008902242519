import { useRef, FC, useState, useEffect } from 'react';
import React from 'react';
import { SessionFeature } from '@exerai/vision-engine-web/dist/src/assessments/types';
import { Chart as ChartJS } from 'chart.js';
import { SessionResultChartImageFeature } from '../types';
import { SessionResultsChartWrapper } from './styles';

interface SessionResultsChartProps {
    sessionFeature: SessionFeature;
    onRenderChart: (id: number, imageData: SessionResultChartImageFeature) => void;
}

export const SessionResultsChart: FC<SessionResultsChartProps> = ({ sessionFeature, onRenderChart }) => {
    const chartContainer = useRef<HTMLCanvasElement>(null);
    const [chartInstance, setChartInstance] = useState<ChartJS<'line', number[], string> | null>(null);

    useEffect(() => {
        if (chartContainer?.current && sessionFeature.values) {
            const currentChartContainer = chartContainer.current;

            const nonNulls = sessionFeature.values.filter((v) => v !== null) as number[];
            const dataSet = {
                data: nonNulls,
                borderColor: 'blue',
                backgroundColor: 'white',
                tension: 1,
            };

            const newChartInstance = new ChartJS(currentChartContainer, {
                type: 'line',
                data: {
                    datasets: [dataSet],
                    labels: nonNulls.map((_, i) => i.toString()),
                },
                options: {
                    plugins: {
                        legend: {
                            display: false,
                        },
                    },
                },
            });
            newChartInstance.options.animation = {
                onComplete: () => {
                    onRenderChart(sessionFeature.id, {
                        feature: { name: sessionFeature.name },
                        image: newChartInstance.toBase64Image(),
                    });
                },
            };
            setChartInstance(newChartInstance);
        }
    }, []);

    return (
        <SessionResultsChartWrapper>
            <canvas ref={chartContainer} id="patientChart" />
        </SessionResultsChartWrapper>
    );
};
