import React, { createContext, useEffect, useState } from 'react';
import { Assessment } from '@exerai/vision-engine-web';
import { useExerUser } from '@/common/hooks/ExerUser';
import { useHttpContext } from '@/common/hooks/HttpContext';
import { toasts } from '@/common/toasts';
import { AssessmentsDict, Maybe } from '@/common/types';
import { supplementAssessmentProtocol } from '@/common/utils';
import astGait from '@/components/Scan/Replay/astGait.json';
import asts2s30 from '@/components/Scan/Replay/asts2s30.json';
import { resultIsError } from '@/services/HttpService';

interface IAssessmentsContext {
    assessmentsDict?: AssessmentsDict;
}

export const AssessmentsContext = createContext<IAssessmentsContext>({ assessmentsDict: undefined });

export const AssessmentsProvider = (props) => {
    const { httpService } = useHttpContext();
    const { user } = useExerUser();
    const { errorToast } = toasts;
    const [assessmentsDict, setAssessmentsDict] = useState<AssessmentsDict>();

    const getAssessments = async () => {
        const res = await httpService.adminGetAssessments();
        if (!res || resultIsError(res)) {
            errorToast("Couldn't fetch assessments :(");
        } else {
            const assDict = {};
            res.forEach((ass) => {
                let supplementalAss: Maybe<Partial<Assessment>> = undefined;
                switch (ass.id) {
                    case 'AST-0024':
                        supplementalAss = astGait as unknown as Partial<Assessment>;
                        break;
                    case 'AST-0019':
                        supplementalAss = asts2s30 as unknown as Partial<Assessment>;
                        break;
                    default:
                        supplementalAss = {
                            directives: {
                                startingConditions: {
                                    gate: 'AND',
                                    conditions: [
                                        {
                                            type: 'NONE',
                                        },
                                    ],
                                },
                                endingConditions: {
                                    gate: 'AND',
                                    conditions: [
                                        {
                                            type: 'TIME',
                                            time: 300,
                                        },
                                    ],
                                },
                                gipConditions: { checkpoints: [] },
                                trackedFeatures: [],
                            },
                            resultDefinitions: [],
                        } as unknown as Partial<Assessment>;
                        break;
                }
                if (supplementalAss) {
                    ass = supplementAssessmentProtocol(ass, supplementalAss);
                }
                assDict[ass.id] = ass;
            });
            setAssessmentsDict(assDict);
        }
    };

    useEffect(() => {
        if (!assessmentsDict && user.isAuthenticated && !user.groups.includes('UNKNOWN')) {
            (async () => {
                await getAssessments();
            })();
        }
    }, [user]);

    const ctx = { assessmentsDict };
    return <AssessmentsContext.Provider value={ctx}>{props.children}</AssessmentsContext.Provider>;
};
