import React, { FC, useCallback, useEffect, useRef } from 'react';
import {
    Edge,
    Edges,
    RenderJoint,
    SessionResult,
    UnifiedBodyJoints,
    UnifiedKeypoints,
} from '@exerai/vision-engine-web';
import { getDisplayedEdges, getDisplayedKeypoints } from '@/components/Scan/Replay/pose/utils';
import { SessionResultPoseImageResult } from '../types';

interface SessionResultPoseProps {
    result: SessionResult;
    keypoints: UnifiedKeypoints;
    imageSize: { width: number; height: number };
    onRenderPose: (id: number, imageData: SessionResultPoseImageResult) => void;
}
export const SessionResultPose: FC<SessionResultPoseProps> = ({ result, keypoints, imageSize, onRenderPose }) => {
    const angleCanvasRef = useRef<HTMLCanvasElement | null>(null);

    const MIN_CONFIDENCE = 0.5;
    const DRAW_SCALE = 0.33;

    // use hips to determine center of body, and get delta to middle of canvas
    const xShift = imageSize.width / 2 - keypoints[UnifiedBodyJoints.HIPS_MID].coordinates.x;
    // const xShift = Math.floor(leftShift) + 120;

    const drawKeypoints = useCallback(
        (
            canvasCtx: CanvasRenderingContext2D,
            keypoints: UnifiedKeypoints,
            options: { color: string; lineWidth: number },
        ) => {
            if (canvasCtx) {
                const cj: Path2D[] = [];
                Object.values(keypoints).forEach((kp: RenderJoint) => {
                    if (kp.hidden) {
                        return;
                    }
                    if (kp.confidence && kp.confidence > MIN_CONFIDENCE) {
                        const circle = new Path2D();
                        circle.arc(
                            (kp.coordinates.x + xShift) * DRAW_SCALE,
                            kp.coordinates.y * DRAW_SCALE,
                            options.lineWidth * DRAW_SCALE,
                            0,
                            2 * Math.PI,
                        );
                        canvasCtx.fillStyle = options.color;
                        canvasCtx.fill(circle);
                        cj.push(circle);
                    }
                });
            }
        },
        [xShift],
    );

    const drawEdges = useCallback(
        (canvasCtx: CanvasRenderingContext2D, edges: Edges, options: { color: string; lineWidth: number }) => {
            Object.values(edges).forEach((edge: Edge) => {
                if (edge.hidden) {
                    return;
                }
                const pt1 = edge.joints[0];
                const pt2 = edge.joints[1];

                if (pt1 && pt2) {
                    canvasCtx.beginPath();
                    canvasCtx.moveTo((pt1.coordinates.x + xShift) * DRAW_SCALE, pt1.coordinates.y * DRAW_SCALE);
                    canvasCtx.lineTo((pt2.coordinates.x + xShift) * DRAW_SCALE, pt2.coordinates.y * DRAW_SCALE);
                    canvasCtx.strokeStyle = options.color;
                    canvasCtx.lineWidth = options.lineWidth * DRAW_SCALE;
                    canvasCtx.stroke();
                }
            });
        },
        [xShift],
    );

    useEffect(() => {
        const canvasCtx = angleCanvasRef.current?.getContext('2d');
        if (canvasCtx && keypoints) {
            canvasCtx.clearRect(0, 0, angleCanvasRef.current!.width, angleCanvasRef.current!.height);
            drawEdges(canvasCtx, getDisplayedEdges(keypoints), { color: '#999999', lineWidth: 2 });
            drawKeypoints(canvasCtx, getDisplayedKeypoints(keypoints), { color: '#666666', lineWidth: 3 });
            onRenderPose(result.id, {
                result: { name: result.name, value: result.value, units: result.units },
                image: angleCanvasRef.current!.toDataURL(),
            });
        }
    }, [drawEdges, drawKeypoints, keypoints, onRenderPose, result]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 10 }}>
            <div
                style={{
                    width: imageSize.height * DRAW_SCALE,
                    textAlign: 'center',
                    border: '1px solid #333333',
                    borderRadius: 6,
                }}
            >
                <div style={{ padding: 6, height: 32 }}>
                    <strong>{result.name}</strong> <br />
                    <span style={{ fontSize: 24 }}>
                        {result.value?.toFixed(result.decimals || 2)} {result.units}
                    </span>
                </div>
                <canvas
                    style={{ marginLeft: 'auto', marginRight: 'auto' }}
                    ref={angleCanvasRef}
                    width={imageSize.width * DRAW_SCALE}
                    height={imageSize.height * DRAW_SCALE}
                ></canvas>
            </div>
        </div>
    );
};
