import styled from 'styled-components';

export const ResultActionsWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignContent: 'start',
    height: 42,
}));

export const SessionPoses = styled('div')(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: 8,
    alignItems: 'center',
}));

export const SessionResultsWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'top',
    width: '85%',
    margin: '0 auto',
    border: '1px solid #999999',
    borderRadius: theme.borderRadius,
    padding: theme.spacing(2),
}));

export const SessionResultsValues = styled('div')(({ theme }) => ({
    marginBottom: theme.spacing(2),
}));
